import Loading from 'vue-loading-overlay'
import axios from 'axios'
import PaginationNav from '../../components/PaginationNav.vue'
import PaginationMobile from '../../components/PaginationMobile.vue'
import LoadingComp from '../../components/LoadingComp.vue'

import * as config from '@/config.json'

export default {
  name: 'ProductsSite',
  components: {
    Loading,
    PaginationNav,
    PaginationMobile,
    LoadingComp
  },
  props: ['source'],
  data: () => {
    return {
      isBusy: false,
      permissions: '',
      isAllowedEditProduct: false,
      isAllowedChangeStockProduct: false,
      pagination: {},
      paginationRange: 11,
      currentPage: 1,
      perPage: 8,
      token: null,
      userType: '',
      product: '',
      inactiveProduct: false,
      isInactiveProduct: false,
      productsSite: [],
      fieldsProductsSite: [
        {
          key: 'isInactive',
          label: 'Ativo'
        },
        {
          key: 'imageUrl',
          label: 'Foto'
        },
        {
          key: 'sku',
          label: 'Barras'
        },
        {
          key: 'description',
          label: 'Descrição'
        },
        {
          key: 'availableQuantity',
          label: 'Qtd Estoque'
        },
        {
          key: 'minimumQuantity',
          label: 'Qtd Mínima'
        },
        {
          key: 'maximumQuantity',
          label: 'Qtd Máxima'
        },
        {
          key: 'idealQuantity',
          label: 'Qtd Ideal'
        },
        {
          key: 'supplyQuantity',
          label: 'Sugestão Reposição'
        },
        {
          key: 'costPrice',
          label: 'Vlr Custo'
        },
        {
          key: 'salePrice',
          label: 'Vlr Venda'
        },
        {
          key: 'promotionPrice',
          label: 'Vlr Promoção'
        },
        {
          key: 'loyalPrice',
          label: 'Vlr Fidelizado'
        },
        {
          key: 'groupDescription',
          label: 'Grupo'
        },
        {
          key: 'isBlockedUnderEighteen',
          label: 'Classificação'
        },
        {
          key: 'action',
          label: ''
        }
      ],
      totalRecords: null,
      selectedProductsRadio: 0,
      optionsSelectProductsRadio: [
        {
          item: 0,
          name: 'Todos'
        },
        {
          item: 1,
          name: 'Ativos'
        },
        {
          item: 2,
          name: 'Inativos'
        }
      ],
      selectedOption: null,
      options: [
        {
          value: null,
          text: '--- Selecione ---'
        },
        {
          value: 1,
          text: 'Descrição'
        },
        {
          value: 2,
          text: 'Barras'
        },
        {
          value: 3,
          text: 'Qtd Estoque'
        },
        {
          value: 4,
          text: 'Grupo'
        }
      ],

      selectedOptionStockType: null,
      optionsStockType: [
        {
          value: null,
          text: '--- Selecione ---'
        },
        {
          value: 1,
          text: 'Ideal'
        },
        {
          value: 2,
          text: 'Mínima'
        },
        {
          value: 3,
          text: 'Máxima'
        }
      ],

      selectedOptionStock: null,
      optionsStock: [
        {
          value: null,
          text: '--- Selecione ---'
        },
        {
          value: 1,
          text: 'Igual'
        },
        {
          value: 2,
          text: 'Abaixo'
        },
        {
          value: 3,
          text: 'Acima'
        }
      ],

      isLoading: false,
      companyId: null,
      siteId: null,
      fantasy: '',
      stockProduct: 0,
      stockAdjust: 0,
      currentStockProduct: 0,
      maximumQuantityProduct: 0,
      minimumQuantityProduct: 0,
      idealQuantityProduct: 0,
      costProduct: 0,
      valueProduct: 0,
      promotionPriceProduct: 0,
      loyalPriceProduct: 0,
      state: null,
      stateStockAdjustment: null,
      description: '',
      pagePagination: null,
      unity: null,
      selectedProductsClassificationRadio: 0,
      optionsSelectClassificationProductsRadio: [
        {
          item: 0,
          name: 'Todos'
        },
        {
          item: 1,
          name: 'Livre'
        },
        {
          item: 2,
          name: '18 -'
        }
      ],
      isBlockedUnderEighteen: false,
      imageProduct: null,
      selectedReason: null,
      optionsReasons: [{}],
      reasonStockAdjustmentId: null
    }
  },

  methods: {
    getUserPermissions() {
      this.permissions = JSON.parse(sessionStorage.getItem('permissions'))

      const cost_Price = this.permissions.find(permission => permission.command === 'cost_price')

      if (cost_Price) {
        this.isAllowedEditProduct = true
      } else {
        this.isAllowedEditProduct = false
      }
    },

    buildParamsProductsSite(companyId, siteId, option, optionStock, optionQuantity, selectedProductsRadio, selectedClassificationRadio, pagina) {
      let params = new Object()

      if (companyId != null) {
        params.companyId = parseInt(companyId)
      }

      if (siteId != null) {
        params.siteId = parseInt(siteId)
      }

      if (option == 1) {
        params.description = this.product
      }

      if (option == 2) {
        params.sku = this.product
      }

      if (option == 3 && optionQuantity != null) {
        params.optionStock = optionStock
        params.optionQuantity = optionQuantity
      }

      if (option == 4) {
        params.groupDescription = this.product
      }

      if (selectedProductsRadio == 1) {
        params.isInactive = false
      }

      if (selectedProductsRadio == 2) {
        params.isInactive = true
      }

      if (selectedClassificationRadio == 1) {
        params.isBlockedUnderEighteen = false
      }

      if (selectedClassificationRadio == 2) {
        params.isBlockedUnderEighteen = true
      }

      params.pageSize = this.perPage
      params.page = pagina

      return params
    },

    async clearLocalStorage() {
      localStorage.removeItem('companyIdProductSite')
      localStorage.removeItem('siteIdProductSite')
      localStorage.removeItem('fantasyProductSite')
      localStorage.removeItem('currentPageProductSite')
    },

    async redirect(ref) {
      await this.clearLocalStorage()
      this.$router.push(ref)
    },

    async readSiteParams() {
      if (this.$route.params.companyId) {
        localStorage.setItem('companyIdProductSite', this.$route.params.companyId)
      }

      if (this.$route.params.siteId) {
        localStorage.setItem('siteIdProductSite', this.$route.params.siteId)
      }

      if (this.$route.params.fantasy) {
        localStorage.setItem('fantasyProductSite', this.$route.params.fantasy)
      }

      this.companyId = localStorage.getItem('companyIdProductSite')
      this.siteId = localStorage.getItem('siteIdProductSite')
      this.fantasy = localStorage.getItem('fantasyProductSite')

      await this.searchProductsSite(this.currentPage)
    },

    async searchProductsSite(pagina) {
      const url = `${config.default.urlBase}/sites/products/search`
      const params = this.buildParamsProductsSite(
        this.companyId,
        this.siteId,
        this.selectedOption,
        this.selectedOptionStockType,
        this.selectedOptionStock,
        this.selectedProductsRadio,
        this.selectedProductsClassificationRadio,
        pagina
      )

      try {
        this.isBusy = true
        const response = await axios({
          url: url,
          method: 'get',
          params: params,
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        localStorage.setItem('currentPageProductSite', pagina)

        if (response.status == 200) {
          this.pagination = response.data
          this.pagination.rowsPerPage = this.perPage
          this.pagination.paginationRange = this.paginationRange

          this.totalRecords = response.data.records

          const products = await Promise.all(
            response.data.items.map(async product => {
              return {
                ...product,
                availableQuantity: String(product.availableQuantity).replace('.', ',').trim(),
                maximumQuantity: String(product.maximumQuantity).replace('.', ',').trim(),
                minimumQuantity: String(product.minimumQuantity).replace('.', ',').trim(),
                idealQuantity: String(product.idealQuantity).replace('.', ',').trim(),
                supplyQuantity: String(product.supplyQuantity).replace('.', ',').trim()
              }
            })
          )

          this.productsSite = products
          this.isBusy = false
        }
      } catch (error) {
        console.error({
          errorMessage: `[searchProductsSite] ${error}`
        })

        this.isBusy = false
        this.isLoading = false

        this.productsSite = []
        this.pagination = []
        this.totalRecords = 0

        if (!error.response) {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        }

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        } else if (error.response.status == 404) {
          this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
        } else {
          await this.$alert(`Ocorreu um erro ao buscar os produtos! ${error.response.data.message}`, 'Aviso', 'warning')
        }
      }
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: 'bottom-center'
      })
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
        duration: 3000
      })
    },

    cleanSearch() {
      this.product = ''
      this.searchProductsSite(this.currentPage)
    },

    cleanStockOption() {
      this.selectedOptionStock = null
      this.searchProductsSite(this.currentPage)
    },

    cleanStockType() {
      this.selectedOptionStockType = null
      this.searchProductsSite(this.currentPage)
    },

    navigate(page) {
      this.searchProductsSite(page)
    },

    async allowedChangeStockProduct() {
      this.isAllowedChangeStockProduct = true

      await this.getReasonsStockAdjustment()

      this.stockAdjust = this.stockProduct

      this.$refs.reasonStockAdjustment.show()
    },

    async getReasonsStockAdjustment() {
      try {
        const url = `${config.default.urlBase}/stock-adjustment`

        const response = await axios({
          url,
          method: 'get',
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        if (response.status == 200) {
          this.optionsReasons = await Promise.all(
            response.data.reasons.map(item => {
              return {
                value: item.id,
                text: `${item.id} - ${item.description} - [${item.type}]`
              }
            })
          )
        }
      } catch (error) {
        console.log({ error })
      }
    },

    closeModalReasonStockAdjustment() {
      this.$refs.reasonStockAdjustment.hide()

      this.isAllowedChangeStockProduct = false
      this.selectedReason = null
    },

    confirmReasonStockAdjustment() {
      this.reasonStockAdjustmentId = this.selectedReason
      this.isAllowedChangeStockProduct = false

      this.currentStockProduct = parseFloat(this.stockProduct)
      this.stockProduct = parseFloat(this.stockAdjust)
      this.stockAdjust = null

      this.$refs.reasonStockAdjustment.hide()

      this.selectedReason = null
    },

    verifyState() {
      if (this.stockProduct >= 0 && this.valueProduct >= 0) return true
      else return false
    },

    async showEditProduct(product) {
      this.description = product.description
      this.unity = product.unity
      this.imageProduct = product.imageUrl
      this.productSiteId = product.productSiteId
      this.inactiveProduct = product.isInactive == 1 ? true : false

      this.stockProduct = product.availableQuantity.replace(',', '.')
      this.stockProduct = this.stockProduct == 0 ? '0' : this.stockProduct ? parseFloat(this.stockProduct) : '0'

      this.costProduct = product.costPrice.replace('R$', '').trim()
      this.costProduct = this.costProduct.replace(',', '.')
      this.costProduct = parseFloat(this.costProduct)

      this.valueProduct = product.salePrice.replace('R$', '').trim()
      this.valueProduct = this.valueProduct.replace(',', '.')
      this.valueProduct = this.valueProduct == 0 ? '0' : this.valueProduct ? parseFloat(this.valueProduct) : '0'

      this.promotionPriceProduct = product.promotionPrice.replace('R$', '').trim()
      this.promotionPriceProduct = this.promotionPriceProduct.replace(',', '.')
      this.promotionPriceProduct = parseFloat(this.promotionPriceProduct)

      this.loyalPriceProduct = product.loyalPrice.replace('R$', '').trim()
      this.loyalPriceProduct = this.loyalPriceProduct.replace(',', '.')
      this.loyalPriceProduct = parseFloat(this.loyalPriceProduct)

      this.maximumQuantityProduct = product.maximumQuantity.replace(',', '.').trim()
      this.maximumQuantityProduct = parseFloat(this.maximumQuantityProduct)

      this.minimumQuantityProduct = product.minimumQuantity.replace(',', '.').trim()
      this.minimumQuantityProduct = parseFloat(this.minimumQuantityProduct)

      this.idealQuantityProduct = product.idealQuantity.replace(',', '.').trim()
      this.idealQuantityProduct = parseFloat(this.idealQuantityProduct)

      this.isInactiveProduct = product.isInactiveProduct == 1 ? true : false
      this.isBlockedUnderEighteen = product.isBlockedUnderEighteen

      this.$refs.editProduct.show()
    },

    async buildDataProduct(
      productSiteId,
      isInactive,
      availableQuantity,
      costPrice,
      salePrice,
      promotionPrice,
      loyalPrice,
      minimumQuantity,
      maximumQuantity,
      idealQuantity,
      isBlockedUnderEighteen,
      reasonId
    ) {
      let data = new Object()
      const availableQuantityStr = availableQuantity
      const costPriceStr = costPrice
      const salePriceStr = salePrice
      const promotionPriceStr = promotionPrice
      const loyalPriceStr = loyalPrice
      const minimumQuantityStr = minimumQuantity
      const maximumQuantityStr = maximumQuantity
      const idealQuantityStr = idealQuantity

      data.productSiteId = parseInt(productSiteId)
      data.isInactive = Boolean(isInactive)
      data.availableQuantity = parseFloat(availableQuantityStr) ? parseFloat(availableQuantityStr) : 0
      data.costPrice = parseFloat(costPriceStr) ? parseFloat(costPriceStr) : 0
      data.salePrice = parseFloat(salePriceStr) ? parseFloat(salePriceStr) : 0
      data.promotionPrice = parseFloat(promotionPriceStr) ? parseFloat(promotionPriceStr) : 0
      data.loyalPrice = parseFloat(loyalPriceStr) ? parseFloat(loyalPriceStr) : 0
      data.minimumQuantity = parseFloat(minimumQuantityStr) ? parseFloat(minimumQuantityStr) : 0
      data.maximumQuantity = parseFloat(maximumQuantityStr) ? parseFloat(maximumQuantityStr) : 0
      data.idealQuantity = parseFloat(idealQuantityStr) ? parseFloat(idealQuantityStr) : 0
      data.isBlockedUnderEighteen = Boolean(isBlockedUnderEighteen)

      if (reasonId) {
        data.reasonId = parseInt(reasonId)
      }

      return data
    },

    focus(id) {
      this.$nextTick(() => {
        document.getElementById(id).id === 'stockAdjust' ? document.getElementById(id).select() : document.getElementById(id)
      })
    },

    async closeModalEditProduct() {
      await this.$refs.editProduct.hide()
      this.pagePagination = localStorage.getItem('currentPageProductSite')

      if (!this.pagePagination) {
        await this.searchProductsSite(this.currentPage)
      } else {
        this.navigate(this.pagePagination)
      }
    },

    async updateProduct() {
      this.isLoading = true

      const url = `${config.default.urlBase}/sites/products`
      const data = await this.buildDataProduct(
        this.productSiteId,
        this.inactiveProduct,
        this.stockProduct,
        this.costProduct,
        this.valueProduct,
        this.promotionPriceProduct,
        this.loyalPriceProduct,
        this.minimumQuantityProduct,
        this.maximumQuantityProduct,
        this.idealQuantityProduct,
        this.isBlockedUnderEighteen,
        this.reasonStockAdjustmentId
      )

      try {
        const response = await axios({
          url,
          method: 'PATCH',
          data,
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        if (response.status == 200) {
          this.isLoading = false

          await this.closeModalEditProduct()

          await this.$alert('Produto Atualizado!', 'Sucesso', 'success')
        }
      } catch (error) {
        console.error({
          errorMessage: `[UpdateProduct] ${error}`
        })

        if (this.currentStockProduct != this.stockProduct) {
          this.stockProduct = parseFloat(this.currentStockProduct)
        }

        this.isLoading = false

        if (error.response.status == 500) {
          await this.$alert(`Ocorreu um erro ao atualizar o produto!`, 'Erro', 'error')
        }

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        } else {
          await this.$alert(error.response.data.message, 'Aviso', 'warning')
        }
      }
    },

    validationAdjustment() {
      if (this.selectedReason != null && this.stockAdjust > 0) {
        return true
      }

      return false
    }
  },

  async updated() {
    this.state = await this.verifyState()

    this.stateStockAdjustment = this.validationAdjustment()
  },

  computed: {
    stockProductState() {
      return this.stockProduct ? true : false
    },
    valueProductState() {
      return this.valueProduct ? true : false
    }
  },

  async beforeMount() {
    this.isLoading = true

    this.token = localStorage.getItem('token')
    this.userType = localStorage.getItem('typeUser')

    this.pagePagination = localStorage.getItem('currentPageProductSite')

    await this.readSiteParams()
    this.getUserPermissions()

    this.isLoading = false
  }
}
